import { SignalR_RoleEcommercePermission, SignalR_BusinessRelationPermission, SignalR_RoleAdministrationPermission, SignalR_UserPermission, SignalR_UserStatus } from "root/services/mibp-api-services/_mibp-api-generated.service.types";
import { MySandvikFeatures } from "./services/permission";
import { PermissionPolicy, PermissionPolicyItem } from "./services/permission/permission.service";
import { BusinessRelationPermission, UserStatus } from "./mibp-openapi-gen/models";

const canSeeActiveCart = <PermissionPolicy>{
  permissionMatch: 'any',
  roleEcommercePermissions: [
    SignalR_RoleEcommercePermission.CreateCart,
    SignalR_RoleEcommercePermission.SendWishList,
    SignalR_RoleEcommercePermission.ReviewAndApproveWishList,
    SignalR_RoleEcommercePermission.CheckOutCartPlaceOrder,
    SignalR_RoleEcommercePermission.CheckOutCartRequestQuote,
    SignalR_RoleEcommercePermission.ExportCart,
    SignalR_RoleEcommercePermission.PunchOut
  ]
  // features: Cart should always be visible. But feature ADddToCart will determine if you can add to it or not
};

const allReportPermissions = {

  // BusinessRelation-level reports
  surfaceReports: { userStatus: [SignalR_UserStatus.Active], businessRelationPermissions: [SignalR_BusinessRelationPermission.ViewSurfaceReports] },
  crushingAndScreeningReports: { userStatus: [UserStatus.Active], businessRelationPermissions: [BusinessRelationPermission.ViewCrushingAndScreeningReports] },
  trackReports: { userStatus: [UserStatus.Active], businessRelationPermissions: [BusinessRelationPermission.ViewTrackReports] },

  undergroundFleetReports: <PermissionPolicy>{
    permissionMatch: 'any',
    userStatus: [UserStatus.Active],
    businessRelationPermissions: [
      BusinessRelationPermission.ViewUndergroundReports,
      BusinessRelationPermission.ViewProductivityReportsForTrucks,
      BusinessRelationPermission.ViewProductivityReportsForLoaders,
      BusinessRelationPermission.ViewProductivityReportForUgDrills
    ]
  },

  subscriptionReports:  <PermissionPolicy>{
    permissionMatch: 'any',
    userStatus: [UserStatus.Active],
    businessRelationPermissions: [
      BusinessRelationPermission.ViewCrushingAndScreeningReports,
      BusinessRelationPermission.ViewSurfaceReports,
      BusinessRelationPermission.ViewTrackReports,
      BusinessRelationPermission.ViewUndergroundReports
    ]
  }

  // Feature-level reports (prev. role ecom permissions)
  // backOrder: { roleEcommercePermissions: [SignalR_RoleEcommercePermission.ViewBackOrderReports], features: [MySandvikFeatures.ReportsViewBackOrder] },
  // topOrder: { roleEcommercePermissions: [SignalR_RoleEcommercePermission.ViewTopOrderReports], features: [MySandvikFeatures.ReportsViewTopOrder] }
};

const allAdminPermissions = {
  canManageExternalUsers: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageExternalUsers],
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageUsersAndUserAccess]
  },
  canManageInternalUsers: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageInternalUsers],
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageInternalUsers]
  },
  canManageB2cUsers: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageB2c]
  },
  canCreateB2CUser: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageB2cSica, MySandvikFeatures.AdminManageB2cDigitalservices],
    permissionMatch: 'any'
  },
  canManagePunchoutCxml: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminPunchout],
    userPermissions: [SignalR_UserPermission.PunchoutcXML]
  },
  canManageRoles: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageRoles],
    features: [MySandvikFeatures.AdminManageRoles]
  },
  canManageMessageCenter: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageMessageCenter],
    roleAdminPermissions: [ SignalR_RoleAdministrationPermission.ManageMessageCenter ]
  },
  canManageUpSell: <PermissionPolicy>{
    roleAdminPermissions: [ SignalR_RoleAdministrationPermission.ManageUpSell ],
    features: [MySandvikFeatures.AdminManageUpsell]
  },
  canManageCrossSell: <PermissionPolicy>{
    roleAdminPermissions: [ SignalR_RoleAdministrationPermission.ManageCrossSell ],
    features: [MySandvikFeatures.AdminManageCrossSell]
  },
  canManagePromotions: <PermissionPolicy>{
    roleAdminPermissions: [ SignalR_RoleAdministrationPermission.ManagePromotions ],
    features: [MySandvikFeatures.AdminManagePromotions]
  },
  canManageUsers: <PermissionPolicy>{
    permissionMatch: 'any',
    features: [ MySandvikFeatures.AdminManageExternalUsers, MySandvikFeatures.AdminManageInternalUsers ],
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageUsersAndUserAccess, SignalR_RoleAdministrationPermission.ManageInternalUsers]
  },
  canManageRestrictedItems: {
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageRestrictedItem],
    features: [MySandvikFeatures.AdminManageRestrictedItems]
  },
  canManageTranslations: {
    roleAdminPermissions: [ SignalR_RoleAdministrationPermission.ResourceStringEditor ],
    features: [MySandvikFeatures.AdminManageTranslations]
  },
  canManageOrganizations: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageOrganizations],
    features: [MySandvikFeatures.AdminManageOrganizations]
  },
  canManageCustomers: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageCustomers],
    features: [MySandvikFeatures.AdminManageCustomers]
  },
  isFeatureAdministrator: <PermissionPolicy>{
    isFeatureAdministrator: true
  },
  isSupportTeamMember: <PermissionPolicy>{
    isSupportTeamMember: true
  },
  canManageTermsAndConditions: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageTermsAndConditions],
    features: [MySandvikFeatures.AdminManageTermsAndConditionsPage]
  },
  canViewInternalReporting: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ViewInternalReporting],
    features: [MySandvikFeatures.AdminViewInternalReporting]
  },
  canManageProductEnrichment: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageProductEnrichments],
    features: [MySandvikFeatures.AdminManageProductEnrichment]
  }
};


const shouldSeeReportsMenu: PermissionPolicy = [];

Object.keys(allReportPermissions).forEach(reportName => {
  const reportPermission = allReportPermissions[reportName];
  if (shouldSeeReportsMenu.length > 0) {
    shouldSeeReportsMenu.push('or');
  }
  shouldSeeReportsMenu.push(reportPermission);
});

const shouldSeeAdminMenu: PermissionPolicy = [];

Object.keys(allAdminPermissions).forEach(reportName => {
  const reportPermission = allAdminPermissions[reportName];
  if (shouldSeeAdminMenu.length > 0) {
    shouldSeeAdminMenu.push('or');
  }
  shouldSeeAdminMenu.push(reportPermission);
});

const canSeeShopMenu = <PermissionPolicy>[

  { shouldBeRegistered: true },
  'and',
  [
    canSeeActiveCart,
    'or',
    {
      permissionMatch: 'any',
      roleEcommercePermissions: [
        SignalR_RoleEcommercePermission.TrackOrder,
        SignalR_RoleEcommercePermission.ViewInvoices,
        SignalR_RoleEcommercePermission.CreateCart,
        SignalR_RoleEcommercePermission.SendWishList,
        SignalR_RoleEcommercePermission.ReviewAndApproveWishList,
        SignalR_RoleEcommercePermission.CheckOutCartPlaceOrder,
        SignalR_RoleEcommercePermission.CheckOutCartRequestQuote,
        SignalR_RoleEcommercePermission.ExportCart,
        SignalR_RoleEcommercePermission.PunchOut
      ]
    }
  ]
];

export const allPermissionPolicies = {

  activeCart: canSeeActiveCart,
  canSeeShopMenu: canSeeShopMenu,

  mySandvikDigitalServices: <PermissionPolicy> [
    {
      permissionMatch: 'any',
      userStatus: [SignalR_UserStatus.Active],
      businessRelationPermissions: [SignalR_BusinessRelationPermission.ViewUndergroundReports, SignalR_BusinessRelationPermission.ViewSurfaceReports]
    }
  ],

  viewWarrantyPortal: <PermissionPolicy> [
    {  features: [MySandvikFeatures.WarrantyportalView] }
  ],

  viewWarrantyApplication: <PermissionPolicy> [
    {  features: [MySandvikFeatures.WarrantyportalWarrantyapplication] }
  ],

  viewWarrantyRegistration: <PermissionPolicy> [
    {  features: [MySandvikFeatures.WarrantyportalWarrantyregistration] }
  ],

  viewSmartMate: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.SmartMate],
    features: [MySandvikFeatures.GeneralSmartmate]
  },

  viewRemoteexpertise: <PermissionPolicy>{
    permissionMatch: 'any',
    features: [MySandvikFeatures.GeneralViewRemoteexpertise, MySandvikFeatures.GeneralManageRemoteexpertise]
  },
  canCreateRemoteExpertise: <PermissionPolicy>{
    features: [MySandvikFeatures.GeneralManageRemoteexpertise]
  },
  checkout: <PermissionPolicy> {
    permissionMatch: 'any',
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CheckOutCartPlaceOrder, SignalR_RoleEcommercePermission.CheckOutCartRequestQuote],
    features: [MySandvikFeatures.ShopPlaceOrder, MySandvikFeatures.ShopRequestQuotation]
  },

  netPriceOnlyPermission: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.NetPriceOnly]
  },

  canCheckoutOrder: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CheckOutCartPlaceOrder],
    features: [MySandvikFeatures.ShopPlaceOrder]
  },

  canRequestQuote: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CheckOutCartRequestQuote],
    features: [MySandvikFeatures.ShopRequestQuotation]
  },

  canExportCarts: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.ExportCart],
    features: [MySandvikFeatures.ShopExportCart]
  },

  canUseTemplates: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.MyOrderTemplate],
    features: [MySandvikFeatures.ShopTemplates]
  },

  sentCarts: <PermissionPolicy> {
    permissionMatch: 'any',
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CreateCart, SignalR_RoleEcommercePermission.TrackOrder]
  },

  savedCarts: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CreateCart]
  },
  myfleetInspectionReports: <PermissionPolicyItem>{
    roleEcommercePermissions : [SignalR_RoleEcommercePermission.InspectionReports],
    features: [MySandvikFeatures.MyfleetInspectionReports]
  },
  myfleetOperationalReadinessReports: <PermissionPolicyItem>{
    features: [MySandvikFeatures.MyfleetOperationalReadinessReports]
  },
  myfleetExtendedWarrantyServiceRecords: <PermissionPolicyItem>{
    roleEcommercePermissions : [SignalR_RoleEcommercePermission.MaintenanceRecords],
    features: [MySandvikFeatures.MyfleetExtendedWarrantyServiceRecords]
  },
  viewOrdersAndQuotations: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.TrackOrder],
    features: [MySandvikFeatures.ShopOrdersAndQuotations]
  },

  canPunchOut: <PermissionPolicy> {
    isPunchoutUser: true
  },

  canSeeFleetDocuments: <PermissionPolicy>[
    {
      roleEcommercePermissions: [SignalR_RoleEcommercePermission.InspectionReports],
      features: [MySandvikFeatures.MyfleetInspectionReports]
    },
    'or',
    {
      roleEcommercePermissions: [SignalR_RoleEcommercePermission.MaintenanceRecords],
      features: [MySandvikFeatures.MyfleetExtendedWarrantyServiceRecords]

    },
    'or',
    {
     features: [MySandvikFeatures.MyfleetOperationalReadinessReports]
    }

  ],

  isSupportTeamMember: <PermissionPolicy> {
    isSupportTeamMember: true
  },

  canSeeAdminMenu: shouldSeeAdminMenu,
  canManageRestrictedItems: allAdminPermissions.canManageRestrictedItems,
  canManageTranslations: allAdminPermissions.canManageTranslations,
  canManageOrganizations: allAdminPermissions.canManageOrganizations,
  canManageCustomers: allAdminPermissions.canManageCustomers,
  canManageRoles: allAdminPermissions.canManageRoles,
  canManagePunchoutCxml: allAdminPermissions.canManagePunchoutCxml,
  canManageExernalUsers: allAdminPermissions.canManageExternalUsers,
  canManageInternalUsers: allAdminPermissions.canManageInternalUsers,
  canManageB2cUsers: allAdminPermissions.canManageB2cUsers,
  canCreateB2CUser: allAdminPermissions.canCreateB2CUser,
  canManageMessageCenter: allAdminPermissions.canManageMessageCenter,
  canManageUsers: allAdminPermissions.canManageUsers,
  canManagePromotions: allAdminPermissions.canManagePromotions,
  canManageCrossSell: allAdminPermissions.canManageCrossSell,
  canManageUpSell: allAdminPermissions.canManageUpSell,
  canManageTermsAndConditions: allAdminPermissions.canManageTermsAndConditions,
  canViewInternalReporting: allAdminPermissions.canViewInternalReporting,
  canManageProductEnrichment:allAdminPermissions.canManageProductEnrichment,

  canManageFleetDocuments: <PermissionPolicy>[
    {
      roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageInspectionReports],
      features: [MySandvikFeatures.MyfleetManageInspectionReports]
    },
    'or',
    {
      roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageMaintenanceRecords],
      features: [MySandvikFeatures.MyfleetExtendedWarrantyServiceRecords]

    },
    'or',
    {

      features: [MySandvikFeatures.MyfleetOperationalReadinessReports]

    }
  ],

  canManageOperationalReadinessReports: <PermissionPolicyItem>{
    features: [MySandvikFeatures.MyfleetManageOperationalReadinessReports]
  },
  canManageInspectionReports: <PermissionPolicyItem>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageInspectionReports],
    features: [MySandvikFeatures.MyfleetManageInspectionReports]
  },
  canManageExtendedWarrantyServiceRecords : <PermissionPolicyItem>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageMaintenanceRecords],
    features: [MySandvikFeatures.MyfleetManageExtendedWarrantyServiceRecords]
  },
  editHelpPage:<PermissionPolicy>{
    roleAdminPermissions:[SignalR_RoleAdministrationPermission.EditHelpPage],
    features:[MySandvikFeatures.AdminManageHelpPage]
  },
  quotations: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.TrackOrder],
    features: [MySandvikFeatures.ShopOrdersAndQuotations]
  },

  viewSubscriptions: <PermissionPolicy>[
    { roleEcommercePermissions: [SignalR_RoleEcommercePermission.ViewSubscriptions] },
    'or',
    { roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageSubscriptions] }
  ],

  canManageSubscriptions: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageSubscriptions],
    features: [] // EMpty features means this will always be hidden
  },

  canManagerNewsArticles: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageNewsarticles]
  },

  canSeeNewsArticles: <PermissionPolicy>{
    features: [MySandvikFeatures.GeneralViewNewsarticles]
  },

  bulletins: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.Bulletins],
    features: [MySandvikFeatures.BulletinsView]
  },

  manageBulletins: <PermissionPolicy>{
    roleAdminPermissions: [SignalR_RoleAdministrationPermission.ManageBulletins],
    features: [MySandvikFeatures.BulletinsManage]
  },

  canSeeBulletinReports: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.ViewBulletinReport],
    features: [MySandvikFeatures.BulletinsViewReports]
  },
  canSeeSupportCases: <PermissionPolicy>{
    features: [MySandvikFeatures.GeneralViewSupportcases]
  },


  machineManuals: <PermissionPolicy>
    // With features: User should be able to see manuals if they have access to Machines + Electronic Manuals (Parts catalogue)
    [
      {
        roleEcommercePermissions: [SignalR_RoleEcommercePermission.InstructionAndSafetyManuals],
        features: [MySandvikFeatures.MyfleetElectronicManuals]
      },
      'and',
      {
        roleEcommercePermissions: [ SignalR_RoleEcommercePermission.ECatalogue ],
        features: [MySandvikFeatures.MyfleetElectronicManuals]
      },
      'and',
      {
        roleEcommercePermissions: [ SignalR_RoleEcommercePermission.MyFleet ],
        features: [MySandvikFeatures.MyfleetViewMachines]
      }
    ],

  promotions: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.Promotions],
    features: [MySandvikFeatures.ShopPromotions]
  },
  creditLimit: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CreditLimit],
    features: [MySandvikFeatures.ShopViewCreditLimit]
  },
  canSeeInvoices: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.ViewInvoices],
    features: [MySandvikFeatures.ShopViewInvoices]
  },

  canSeeCart: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CreateCart],
    features: [MySandvikFeatures.ShopCart]
  },

  canAddToCart: <PermissionPolicy>{
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.CreateCart],
    features: [MySandvikFeatures.ShopCart]
  },

  canActAs: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.ActAs],
    features: [MySandvikFeatures.GeneralActAs]
  },

  canSeePrices: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.PriceEnquiry],
    features: [MySandvikFeatures.ShopShowProductPrices]
  },

  canSeeAvailability: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.StockAvailabilityEnquiry],
    features: [MySandvikFeatures.ShopProductAvailability]
  },

  canSeeExpectedDeliveryDate: <PermissionPolicy> {
    features: [MySandvikFeatures.ShopProductExpecteddeliverydate]
  },

  canShareTemplates: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.ShareTemplate],
    features: [MySandvikFeatures.ShopShareUnshareTemplates]
  },

  canUnShareTemplates: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.UnShareTemplate],
    features: [MySandvikFeatures.ShopShareUnshareTemplates]
  },

  viewMyRockTools: <PermissionPolicy> {
    features: [MySandvikFeatures.GeneralMyrocktools]
  },

  isPunchoutMultiProfileUser: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.MultiplePunchOutProfiles],
    isPunchoutMultiProfileUser: true
  },

  canSeeElectronicManuals: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.ECatalogue],
    features: [MySandvikFeatures.MyfleetElectronicManuals]
  },

  canDownloadManuals: <PermissionPolicy> {
    features: [MySandvikFeatures.MyfleetDownloadManuals]
  },

  canSeePartsManuals: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.PartsManuals],
    features: [MySandvikFeatures.ShopPartsManuals],
  },

  canUpdateMachines: <PermissionPolicy> {
    roleEcommercePermissions: [SignalR_RoleEcommercePermission.AllowUpdateMachines],
    features: [MySandvikFeatures.MyfleetUpdateMachine]
  },

  /**
   * Who should be able to search for parts
  */
  search: <PermissionPolicy>{
    permissionMatch: 'any',
    userStatus: [UserStatus.Active],
    // roleEcommercePermissions: [ RoleEcommercePermission.POCSearch, RoleEcommercePermission.FindParts],
    // features: // Search is available to everyone so let's keep features undefined
  },

  myfleet: <PermissionPolicy>{
    roleEcommercePermissions: [ SignalR_RoleEcommercePermission.MyFleet ],
    features: [MySandvikFeatures.MyfleetViewMachines]
  },

  myFleetfleet3dmodels: <PermissionPolicy> {
    features: [MySandvikFeatures.Myfleet3dmodels]
  },

  canViewMaintenancePlanner: <PermissionPolicy>[{features: [MySandvikFeatures.MyfleetMaintenancePlanner]}, 'or', {features: [MySandvikFeatures.MyfleetMaintenancePlannerManage]}
]

  ,
  canEditMaintenancePlanner: <PermissionPolicy>{
    features: [MySandvikFeatures.MyfleetMaintenancePlannerManage]
  },
  machineOrder: <PermissionPolicy>{
    features: [MySandvikFeatures.MyfleetViewMachineOrders]
  },


  canSeeReportsMenu: shouldSeeReportsMenu,
  canSeeSubscriptionReports:  allReportPermissions.subscriptionReports,
  canSeeUndergroundFleetSubscriptionReports: allReportPermissions.undergroundFleetReports,
  canSeeSurfaceReports: allReportPermissions.surfaceReports,
  canSeeCrushingAndScreeningReports: allReportPermissions.crushingAndScreeningReports,
  canSeeTrackReports: allReportPermissions.trackReports,
  canManageFeatures: allAdminPermissions.isFeatureAdministrator,
  canManageChangelog: <PermissionPolicy>{
    roleAdminPermissions: [ SignalR_RoleAdministrationPermission.ManageChangelog ],
    features: [MySandvikFeatures.AdminManageChangelog]
  },

  supportLiveChat: <PermissionPolicy> [
    { features: [MySandvikFeatures.GeneralSupportLiveChat]},
  ],

  canViewKitFinder: <PermissionPolicy> [
    canSeeShopMenu,
    'and',
    { features: [MySandvikFeatures.MyfleetViewMachines],
      roleEcommercePermissions: [ SignalR_RoleEcommercePermission.MyFleet ]}
  ],
  canViewPaymentPage: <PermissionPolicy> [
    {features: [MySandvikFeatures.ShopPlaceOrderMibppayment]}
  ],

  canManageMachineUpgrade: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageMachineUpgrades]
  },

  canViewMyFleetMachineUpgrade: <PermissionPolicy>{
    features: [MySandvikFeatures.MyfleetViewMachineUpgrades]
  },

  canManageProductNotifications: <PermissionPolicy>{
    features: [MySandvikFeatures.AdminManageProductNotifications]
  },
  canSeeComponentFinder: <PermissionPolicy>{
    features: [MySandvikFeatures.ShopShowComponentFinder]
  },
};
